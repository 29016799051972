import React, { useState, useEffect, Fragment } from 'react'
import { toast } from "react-toastify";
import Select from 'react-select';
import { useHistory } from 'react-router-dom'
import API from "../../../helpers/api";
import FNSpinner from "../../../components/FNSpinner";

const CreateJob = () => {
    const [loading, setLoading] = useState(false);
    const [vehicleId, setVehicleId] = useState("");
    const [vehicles, setVehicles] = useState([]);
    const [vehicle, setVehicle] = useState({});
    const [repairedby, setRepairedBy] = useState("");
    const [testedby, setTestedBy] = useState("");
    const [date, setDate] = useState("");
    const [signature, setSignature] = useState("");
    const [worksdone, setWorksDone] = useState("");
    const [additionalworks, setAdditionalWorks] = useState("");

    const [items, setItems] = useState({
        removevaluables: false,
        radioWorking: false,
        cigarettelighter: false,
        enginewarninglights: false,
        radioinworkingorder: false,
        jack: false,
        jackhandle: false,
        sparetyre: false,
        floormarts: false,
        toolkit: false,
        hubcaps: false,
        fuele: false,
        fuel14: false,
        fuel12: false,
        fuel34: false,
        fuelf: false
    });

    const history = useHistory();

    const handleCheckboxChange = (e) => {
        const { id, checked } = e.target;
        setItems(prevState => ({
            ...prevState,
            [id]: checked
        }));
    };

    const loadVehicle = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/vehicle`);
            console.log(res)
            setVehicles(res?.data.vehicle);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    const fetchVehicleDetails = async (id) => {
        try {
            const res = await API.get(`/vehicle/${id}`);
            console.log(res)
            setVehicle(res?.data.vehicle);
        } catch (error) {
            console.log("Error fetching vehicle details", error);
        }
    };

    const handleChangeVehicle = (selectedOption) => {
        setVehicleId(selectedOption.value);
        fetchVehicleDetails(selectedOption.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const requestData = {
            vehicleId,
            items,
            date,
            repairedby,
            testedby,
            signature,
            worksdone,
            additionalworks
        };
        console.log(requestData)
        try {
            const response = await API.post("/jobcard", requestData);
            setLoading(false);
            history.push('/jobcard/view')
            toast.success(`Job Card Has Been Added Successfully`);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
            toast.error("Error while Adding Job Card");
        }
    };

    useEffect(() => {
        loadVehicle();
    }, []);

    return (
        <Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="d-sm-flex align-items-center justify-content-between mb-3">
                        <h4 className="mb-sm-0 font-size-18">Create Job Card</h4>
                        <div className="page-title-right">
                            <button onClick={handleSubmit} class="btn btn-primary w-md waves-effect waves-light">Add Job Card Details</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-3">
                    <div class="mb-3">
                        <label for="kycselectcity-input" class="form-label">Select Vehicle Number Plate</label>
                        <Select
                            defaultValue={vehicleId}
                            onChange={handleChangeVehicle}
                            options={vehicles.map(vehicle => ({ value: vehicle.id, label: vehicle.numberplate }))}
                            placeholder="Select Vehicle Number Plate"
                        />
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <div class="table-responsive">
                                <table class="table table-bordered border-secondary table-sm">
                                    <tbody>
                                        <tr>
                                            <th scope="row" style={{ backgroundColor: '#f2f2f2' }}>Customer</th>
                                            <td>{vehicle.customer ? vehicle.customer.fullname : ""}</td>
                                            <th scope="row" style={{ backgroundColor: '#f2f2f2' }}>Address</th>
                                            <td>{vehicle.customer ? vehicle.customer.address : ""}</td>
                                            <th scope="row" style={{ backgroundColor: '#f2f2f2' }}>TIN</th>
                                            <td>{vehicle.customer ? vehicle.customer.tin : ""}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ backgroundColor: '#f2f2f2' }}>Number Plate</th>
                                            <td>{vehicle ? vehicle.numberplate : ""}</td>
                                            <th scope="row" style={{ backgroundColor: '#f2f2f2' }}>Make</th>
                                            <td>{vehicle.make ? vehicle.make.name : ""}</td>
                                            <th scope="row" style={{ backgroundColor: '#f2f2f2' }}>Model</th>
                                            <td>{vehicle.model ? vehicle.model.name : ""}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ backgroundColor: '#f2f2f2' }}>Engine Number</th>
                                            <td>{vehicle ? vehicle.engineno : ""}</td>
                                            <th scope="row" style={{ backgroundColor: '#f2f2f2' }}>Engine Size</th>
                                            <td>{vehicle ? vehicle.enginesize : ""}</td>
                                            <th scope="row" style={{ backgroundColor: '#f2f2f2' }}>Chassis Number</th>
                                            <td>{vehicle ? vehicle.chassisno : ""}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ backgroundColor: '#f2f2f2' }}>YOM</th>
                                            <td>{vehicle ? vehicle.year : ""}</td>
                                            <th scope="row" style={{ backgroundColor: '#f2f2f2' }}>Mileage</th>
                                            <td>{vehicle ? vehicle.mileage : ""}</td>
                                            <th scope="row" style={{ backgroundColor: '#f2f2f2' }}>Transmission</th>
                                            <td>{vehicle ? vehicle.transmission : ""}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-2">
                            <div>
                                <div className="form-check form-checkbox-outline form-check-primary mb-3">
                                    <input className="form-check-input" type="checkbox"
                                        id="enginewarninglights"
                                        checked={items.enginewarninglights}
                                        onChange={handleCheckboxChange} />
                                    <label className="form-check-label" htmlFor="customCheckcolor1">
                                        Engine Warning Lights
                                    </label>
                                </div>
                                <div className="form-check form-checkbox-outline form-check-success mb-3">
                                    <input className="form-check-input" type="checkbox"
                                        id="radioinworkingorder"
                                        checked={items.radioinworkingorder}
                                        onChange={handleCheckboxChange} />
                                    <label className="form-check-label" htmlFor="customCheckcolor2">
                                        Radio in Working Order
                                    </label>
                                </div>
                                <div className="form-check form-checkbox-outline form-check-info mb-3">
                                    <input className="form-check-input" type="checkbox"
                                        id="cigarettelighter"
                                        checked={items.cigarettelighter}
                                        onChange={handleCheckboxChange} />
                                    <label className="form-check-label" htmlFor="customCheckcolor3">
                                        Cigarette lighter
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="col-2">
                            <div>
                                <div className="form-check form-checkbox-outline form-check-info mb-3">
                                    <input className="form-check-input" type="checkbox"
                                        id="jack"
                                        checked={items.jack}
                                        onChange={handleCheckboxChange} />
                                    <label className="form-check-label" htmlFor="customCheckcolor3">
                                        jack
                                    </label>
                                </div>
                                <div className="form-check form-checkbox-outline form-check-primary mb-3">
                                    <input className="form-check-input" type="checkbox"
                                        id="jackhandle"
                                        checked={items.jackhandle}
                                        onChange={handleCheckboxChange} />
                                    <label className="form-check-label" htmlFor="customCheckcolor1">
                                        jack Handle
                                    </label>
                                </div>

                                <div className="form-check form-checkbox-outline form-check-info mb-3">
                                    <input className="form-check-input" type="checkbox"
                                        id="sparetyre"
                                        checked={items.sparetyre}
                                        onChange={handleCheckboxChange} />
                                    <label className="form-check-label" htmlFor="customCheckcolor3">
                                        Spare Tyre
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="col-2">
                            <div>
                                <div className="form-check form-checkbox-outline form-check-warning mb-3">
                                    <input className="form-check-input" type="checkbox"
                                        id="floormarts"
                                        checked={items.floormarts}
                                        onChange={handleCheckboxChange} />
                                    <label className="form-check-label" htmlFor="customCheckcolor4">
                                        Floor Marts
                                    </label>
                                </div>
                                <div className="form-check form-checkbox-outline form-check-success mb-3">
                                    <input className="form-check-input" type="checkbox"
                                        id="toolkit"
                                        checked={items.toolkit}
                                        onChange={handleCheckboxChange} />
                                    <label className="form-check-label" htmlFor="customCheckcolor2">
                                        Tool Kit
                                    </label>
                                </div>
                                <div className="form-check form-checkbox-outline form-check-warning mb-3">
                                    <input className="form-check-input" type="checkbox"
                                        id="hubcaps"
                                        checked={items.hubcaps}
                                        onChange={handleCheckboxChange} />
                                    <label className="form-check-label" htmlFor="customCheckcolor4">
                                        Hub Caps
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="col-2">
                            <div>
                                <div className="form-check form-checkbox-outline form-check-primary mb-3">
                                    <input className="form-check-input" type="checkbox"
                                        id="fuele"
                                        checked={items.fuele}
                                        onChange={handleCheckboxChange} />
                                    <label className="form-check-label" htmlFor="customCheckcolor1">
                                        Fuel E
                                    </label>
                                </div>
                                <div className="form-check form-checkbox-outline form-check-success mb-3">
                                    <input className="form-check-input" type="checkbox"
                                        id="fuel14"
                                        checked={items.fuel14}
                                        onChange={handleCheckboxChange} />
                                    <label className="form-check-label" htmlFor="customCheckcolor2">
                                        Fuel 1/4
                                    </label>
                                </div>
                                <div className="form-check form-checkbox-outline form-check-info mb-3">
                                    <input className="form-check-input" type="checkbox"
                                        id="fuel12"
                                        checked={items.fuel12}
                                        onChange={handleCheckboxChange} />
                                    <label className="form-check-label" htmlFor="customCheckcolor3">
                                        Fuel 1/2
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="col-2">
                            <div>
                                <div className="form-check form-checkbox-outline form-check-primary mb-3">
                                    <input className="form-check-input" type="checkbox"
                                        id="fuel34"
                                        checked={items.fuel34}
                                        onChange={handleCheckboxChange} />
                                    <label className="form-check-label" htmlFor="customCheckcolor1">
                                        Fuel 3/4
                                    </label>
                                </div>
                                <div className="form-check form-checkbox-outline form-check-success mb-3">
                                    <input className="form-check-input" type="checkbox"
                                        id="fuelf"
                                        checked={items.fuelf}
                                        onChange={handleCheckboxChange} />
                                    <label className="form-check-label" htmlFor="customCheckcolor2">
                                        Fuel F
                                    </label>
                                </div>
                                <div className="form-check form-checkbox-outline form-check-success mb-3">
                                    <input className="form-check-input" type="checkbox"
                                        id="removevaluables"
                                        checked={items.removevaluables}
                                        onChange={handleCheckboxChange} />
                                    <label className="form-check-label" htmlFor="customCheckcolor2">
                                        Remove Valuables
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="mb-3">
                                <label for="kycselectcity-input" class="form-label">Repaired By</label>
                                <input type="text" class="form-control" placeholder=""
                                    value={repairedby}
                                    onChange={(e) => setRepairedBy(e.target.value)}
                                />
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="mb-3">
                                <label for="kycselectcity-input" class="form-label">Tested By</label>
                                <input type="text" class="form-control" placeholder=""
                                    value={testedby}
                                    onChange={(e) => setTestedBy(e.target.value)}
                                />
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="mb-3">
                                <label for="kycselectcity-input" class="form-label">Date</label>
                                <input type="date" class="form-control" placeholder=""
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                />
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="mb-3">
                                <label for="kycselectcity-input" class="form-label">Signature</label>
                                <input type="text" class="form-control" placeholder=""
                                    value={signature}
                                    onChange={(e) => setSignature(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <h5 class="card-title">Works Done</h5>
                            <textarea class="form-control" rows="3" placeholder="Enter Description of Works Done..."
                                value={worksdone}
                                onChange={(e) => setWorksDone(e.target.value)}
                            />
                        </div>
                        <div className="col-6">
                            <h5 class="card-title">Additional Pending Works</h5>
                            <textarea class="form-control" rows="3"
                                placeholder="Enter Description of any other pending works that need worked on..."
                                value={additionalworks}
                                onChange={(e) => setAdditionalWorks(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default CreateJob