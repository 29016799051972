import React, { Fragment, useState, useEffect, useRef } from "react";
import moment from 'moment';
import ReactToPrint from "react-to-print";
import CurrencyFormat from 'react-currency-format';
import API from "../../../helpers/api";
import logo from './ssejp1.png'

const PrintInvoice = ({ match }) => {

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [proforma, setProforma] = useState({});

    const { id } = match.params;

    const componentRef = useRef();

    const loadProforma = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/proforma/${id}`);
            setProforma(res?.data.proforma);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    const loadItems = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/proforma/items/${id}`);
            setItems(res?.data.items);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        // loadJobCard();
        loadItems();
    }, []);

    useEffect(() => {
        loadProforma();
    }, []);
    return (
        <Fragment>
            <div className="row d-flex justify-content-center" ref={componentRef}>
                <div className="col-10">
                    <div className="card">
                        <div className="card-body">
                                <div style={{width: '100%', height: '30vh', overflow: 'hidden'}}>
                                    <img src={logo} alt="" style={{width: '100%', height: '100%', objectFit: 'contain' }}/>
                                </div>

                            <section className='d-flex flex-row justify-content-between mt-4'>
                                <div>
                                    <h5>Invoice No: {proforma.id}/2024</h5>
                                    {/* <h5>Our Ref: TECHJOB00310</h5> */}
                                </div>
                                <div>
                                    <h5> Job Card No: {proforma.jobcard ? proforma.jobcard.id : ''} / 2024</h5>
                                    {/* <h5> INVOICE NO: </h5> */}
                                </div>
                            </section>
                            <section className='d-flex flex-row justify-content-between mt-4'>
                                <div>
                                    <h4> M/S : {proforma.jobcard ? proforma.jobcard.vehicle.customer.fullname : ''}</h4>
                                    <p>  Address: {proforma.jobcard ? proforma.jobcard.vehicle.customer.address : ''}</p>
                                </div>
                                <div>
                                    <h5> Invoice Date: {moment(proforma.proformaDate).format('YYYY-MM-DD')}</h5>
                                    <h5>  Number Plate:  {proforma.jobcard ? proforma.jobcard.vehicle.numberplate : ''}</h5>
                                </div>

                            </section>
                            <section>
                                {/* <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">Job Card Items Worked On</h3>
                                </div> */}
                                <div class="table-responsive">
                                    <table class="table table-striped table-bordered border-secondary table-sm">
                                        <thead className="table-dark">
                                            <tr>
                                                <th>Item Description</th>
                                                <th>Qty</th>
                                                <th class="text-end">Rate</th>
                                                <th class="text-end">Total Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items &&
                                                items.map((item) => (
                                                    <tr key={item.id}>
                                                        <td>{item.item}</td>
                                                        <td>{item.qty}</td>
                                                        <td class="text-end">
                                                            <CurrencyFormat
                                                                value={item.rate}
                                                                displayType="text"
                                                                thousandSeparator
                                                            /></td>
                                                        <td class="text-end">
                                                            <CurrencyFormat
                                                                value={item.amt}
                                                                displayType="text"
                                                                thousandSeparator
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            <tr>
                                                <td colspan="3" class="text-end"><strong>Sub Total Amount</strong></td>
                                                <td class="text-end">
                                                    <CurrencyFormat
                                                        value={proforma.subTotal}
                                                        displayType="text"
                                                        thousandSeparator
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" class="border-0 text-end">
                                                    <strong>Discount</strong></td>
                                                <td class="text-end">
                                                    <CurrencyFormat
                                                        value={proforma.discount}
                                                        displayType="text"
                                                        thousandSeparator
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" class="border-0 text-end">
                                                    <strong>Net Total Amount</strong></td>
                                                <td class="text-end">
                                                    <CurrencyFormat
                                                        value={proforma.netTotal}
                                                        displayType="text"
                                                        thousandSeparator
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" class="border-0 text-end">
                                                    <strong>VAT</strong></td>
                                                <td class="text-end">
                                                    <CurrencyFormat
                                                        value={proforma.vat}
                                                        displayType="text"
                                                        thousandSeparator
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" class="text-end">
                                                    <strong>Gross Total Amount</strong></td>
                                                <td class="text-end"><h4 class="m-0">
                                                    <CurrencyFormat
                                                        value={proforma.grossTotal}
                                                        displayType="text"
                                                        thousandSeparator
                                                    />
                                                </h4></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </section>
                            {/* <section>
                                <p>Notes to client</p>
                            </section> */}
                            <footer>
                                <ul className='list-unstyled'>
                                    <li>STANBIC BANK : 9030017839968 - GARDEN CITY.</li>
                                    <li>AC NAME: SSEJP ENGINEERING AND CONSULTS-SMC LTD</li>
                                    <li>  SWIFT: SBICUGKX</li>
                                </ul>
                            </footer>

                        </div>
                    </div>
                </div>
            </div>
            <div className="row d-flex justify-content-center mb-3">
                <div className="col-10">
                        <div class="d-print-none">
                            <div class="float-end">
                                <ReactToPrint
                                    trigger={() => <button class="btn btn-warning w-md waves-effect waves-light">Print Vehicle Invoice</button>}
                                    content={() => componentRef.current}
                                />
                            </div>
                        </div>
                </div>
            </div>
            
        </Fragment>
    )
}

export default PrintInvoice