import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Select from 'react-select';
import API from "../../../helpers/api";
import FNSpinner from '../../../components/FNSpinner'

const AddVehicle = ({ refresh, close }) => {

    const [loading, setLoading] = useState(false);
    const [numberplate, setNumberPlate] = useState("");
    const [chassisno, setChassisNo] = useState("");
    const [engineno, setEngineNo] = useState("");
    const [enginesize, setEngineSize] = useState("");
    const [makeId, setMakeId] = useState("");
    const [modelId, setModelId] = useState("");
    const [custId, setCustId] = useState("");
    const [year, setYear] = useState("");
    const [fuel, setFuel] = useState("");
    const [transmission, setTransmission] = useState("");
    const [mileage, setMileage] = useState("");
    const [customers, setCustomers] = useState([]);
    const [filteredModels, setFilteredModels] = useState([]);

    const [model, setModel] = useState([]);
    const [makes, setMakes] = useState([]);

    const loadModel = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/model`);
            console.log(res)
            setModel(res?.data.model);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    const loadCustomers = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/customers`);
            setCustomers(res?.data.customers);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    const handleChangeModel = (selectedOption) => {
        setModelId(selectedOption.value);
    };

    const handleChangeMake = (selectedOption) => {
        const selectedMakeId = selectedOption.value;
        setMakeId(selectedMakeId);
        filterModels(selectedMakeId);
    };

    const handleChangeCustomer = (selectedOption) => {
        setCustId(selectedOption.value);
    };

    const loadMake = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/make/t`);
            setMakes(res?.data.make);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    const filterModels = (makeId) => {
        const filtered = model.filter(model => model.makeId === makeId);
        setFilteredModels(filtered);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = {
            numberplate,
            chassisno,
            engineno,
            enginesize,
            makeId,
            modelId,
            year,
            fuel,
            transmission,
            mileage,
            custId
        };
        try {
            const response = await API.post(`/vehicle`, data,);
            setLoading(false);
            console.log("added vehicles ===>", response);
            close();
            refresh();
            toast.success("Vehicle Created Successfully !!");
        } catch {
            setLoading(false);
            toast.error("Error while Adding Vehicle");
        }
    };

    useEffect(() => {
        loadModel();
        loadMake();
        loadCustomers();
    }, []);

    return (
        <div class="card">
            <div class="card-body">
                <div className="row">
                    <div className="col-4">
                        <div class="mb-3">
                            <label class="form-label">Number Plate</label>
                            <input type="text" class="form-control" autocomplete="off" placeholder="Number Plate"
                                value={numberplate}
                                onChange={(e) => setNumberPlate(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div class="mb-3">
                            <label class="form-label">Vehicle Make</label>
                            <Select
                                defaultValue={makeId}
                                onChange={handleChangeMake}
                                options={makes.map(make => ({ value: make.id, label: make.name }))}
                                placeholder="Select Vehicle Make"
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div class="mb-3">
                            <label class="form-label">Vehicle Model</label>
                            <Select

                                defaultValue={modelId}
                                onChange={handleChangeModel}
                                options={filteredModels.map(type => ({ value: type.id, label: type.name }))}
                                placeholder="Select Vehicle Model"
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div class="mb-3">
                            <label class="form-label">Chassis Number</label>
                            <input type="text" class="form-control" autocomplete="off" placeholder="Chassis Number"
                                value={chassisno}
                                onChange={(e) => setChassisNo(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div class="mb-3">
                            <label class="form-label">Engine Number</label>
                            <input type="text" class="form-control" autocomplete="off" placeholder="Engine Number"
                                value={engineno}
                                onChange={(e) => setEngineNo(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div class="mb-3">
                            <label class="form-label">Engine Size</label>
                            <input type="text" class="form-control" autocomplete="off" placeholder="Engine Size"
                                value={enginesize}
                                onChange={(e) => setEngineSize(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div class="mb-3">
                            <label class="form-label">Fuel Type</label>
                            <select class="form-select" aria-label="Select example" value={fuel}
                                onChange={(e) => setFuel(e.target.value)}>
                                <option>Select Fuel Type </option>
                                <option>Diesel</option>
                                <option>Petrol</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-4">
                        <div class="mb-3">
                            <label class="form-label">Year Of Manufacture</label>
                            <input type="text" class="form-control" autocomplete="off" placeholder="Year of Manufacture"
                                value={year}
                                onChange={(e) => setYear(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div class="mb-3">
                            <label class="form-label">Transmission</label>
                            <select class="form-select" aria-label="Select example" value={transmission}
                                onChange={(e) => setTransmission(e.target.value)}>
                                <option>Select Gear Type </option>
                                <option>Manual</option>
                                <option>Automatic</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-4">
                        <div class="mb-3">
                            <label class="form-label">Mileage</label>
                            <input type="text" class="form-control" autocomplete="off" placeholder="Mileage"
                                value={mileage}
                                onChange={(e) => setMileage(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div class="mb-3">
                            <label class="form-label">Customer / Client</label>
                            <Select
                                defaultValue={custId}
                                onChange={handleChangeCustomer}
                                options={customers.map(cust => ({ value: cust.id, label: cust.fullname }))}
                                placeholder="Select Customer"
                            />
                        </div>
                    </div>
                </div>
                <div className="div mt-3">
                    <button class="btn btn-primary me-2" onClick={handleSubmit}>{loading ? <FNSpinner /> : "Add New Vehicle"}</button>
                    <button class="btn btn-outline-primary" onClick={close}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default AddVehicle