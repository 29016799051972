import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import API from "../../../helpers/api";
import FNSpinner from '../../../components/FNSpinner'

const EditVechile = ({ id, close, refresh }) => {

  const [loading, setLoading] = useState(false);
  const [fullname, setFullNames] = useState("");
  const [tin, setTin] = useState("");
  const [custtype, setCustType] = useState("");
  const [address, setAddress] = useState("");
  const [phoneno, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");

  const customerDetails = async () => {
    setLoading(true);
    try {
      const res = await API.get(`/customers/${id}`);
      console.log(res)
      setFullNames(res?.data.customer.fullname);
      setTin(res?.data.customer.tin);
      setCustType(res?.data.customer.custtype);
      setAddress(res?.data.customer.address);
      setPhoneNo(res?.data.customer.phoneno);
      setEmail(res?.data.customer.email);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      fullname,
      tin,
      custtype,
      address,
      phoneno,
      email
    };

    try {
      const response = await API.patch(`/customers/${id}`, data,);
      setLoading(false);
      close();
      refresh();
      toast.success("Customer Details Edited Successfully !!");
    } catch {
      setLoading(false);
      toast.error("Error while Editing  Customer Details");
    }
  };

  useEffect(() => {
    customerDetails();
  }, []);

  return (
    <div class="card">
      <div class="card-body">
        <div className="row">
          <div className="col-6">
            <div class="mb-3">
              <label class="form-label">Full Names</label>
              <input type="text" class="form-control" autocomplete="off" placeholder="Full Names"
                value={fullname}
                onChange={(e) => setFullNames(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div class="mb-3">
              <label class="form-label">Address</label>
              <input type="text" class="form-control" autocomplete="off" placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div class="mb-3">
              <label class="form-label">TIN Number</label>
              <input type="text" class="form-control" autocomplete="off" placeholder="TIN Number"
                value={tin}
                onChange={(e) => setTin(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div class="mb-3">
              <label class="form-label">Customer Type</label>
              <select class="form-select" aria-label="Select example" value={custtype}
                onChange={(e) => setCustType(e.target.value)}>
                <option>Select Customer Type </option>
                <option>Government</option>
                <option>Organization</option>
                <option>Individual</option>
              </select>
            </div>
          </div>

          <div className="col-6">
            <div class="mb-3">
              <label class="form-label">Email</label>
              <input type="text" class="form-control" autocomplete="off" placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div class="mb-3">
              <label class="form-label">Phone Number</label>
              <input type="text" class="form-control" autocomplete="off" placeholder="Phone Number"
                value={phoneno}
                onChange={(e) => setPhoneNo(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="div mt-3">
          <button class="btn btn-primary me-2" onClick={handleSubmit}>{loading ? <FNSpinner /> : "Edit Customer Details"}</button>
          <button class="btn btn-outline-primary" onClick={close}>Cancel</button>
        </div>
      </div>
    </div>
  )
}

export default EditVechile