import React, { useState, useEffect, Fragment } from 'react';
import { toast } from "react-toastify";
import CurrencyFormat from 'react-currency-format';
import { useHistory } from 'react-router-dom';
import API from "../../../helpers/api";
import FNSpinner from "../../../components/FNSpinner";

const AddProforma = ({ match }) => {
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [jobcard, setJobCard] = useState({});
    const [subTotal, setSubTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [grossTotal, setGrossTotal] = useState(0);
    const [vat, setVat] = useState(0);
    const [netTotal, setNetTotal] = useState(0);

    const history = useHistory();
    const { id } = match.params;

    const loadJobCard = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/jobcard/${id}`);
            setJobCard(res?.data.job);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    const createNewRow = () => ({
        id: Date.now(),
        item: '',
        qty: '',
        rate: '',
        amt: 0,
    });

    const addRow = () => {
        setRows([...rows, createNewRow()]);
    };

    const handleInputChange = (id, field, value) => {
        setRows((prevRows) => prevRows.map((row) => {
            if (row.id === id) {
                const updatedRow = { ...row, [field]: value };
                if (field === 'qty' || field === 'rate') {
                    const qty = field === 'qty' ? parseFloat(value) || 0 : parseFloat(updatedRow.qty) || 0;
                    const rate = field === 'rate' ? parseFloat(value) || 0 : parseFloat(updatedRow.rate) || 0;
                    updatedRow.amt = qty * rate;
                }
                return updatedRow;
            }
            return row;
        }));
    };

    const deleteRow = (id) => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            jobcardId: id,
            subTotal,
            discount,
            netTotal,
            vat,
            grossTotal,
            rows: rows.map(row => ({
                item: row.item,
                qty: row.qty,
                rate: row.rate,
                amt: row.amt,
            }))
        };

        try {
            const response = await API.post("/proforma", data);
            setLoading(false);
            history.push('/proforma');
            toast.success(`Proforma Has Been Added Successfully`);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
            toast.error("Error while Adding Proforma");
        }
    };

    useEffect(() => {
        loadJobCard();
        setRows([createNewRow()]);
    }, []);

    useEffect(() => {
        const calculateTotals = () => {
            const subTotal = rows.reduce((total, row) => total + (parseFloat(row.amt) || 0), 0);
            const discountAmount = (discount / 100) * subTotal;
            const netTotal = subTotal - discountAmount;
            const vatAmount = netTotal * 0.18;
            const grossTotal = netTotal + vatAmount;

            setSubTotal(subTotal);
            setGrossTotal(grossTotal);
            setVat(vatAmount);
            setNetTotal(netTotal);
        };

        calculateTotals();
    }, [rows, discount]);

    return (
        <Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="d-sm-flex align-items-center justify-content-between mb-3">
                        <h4 className="mb-sm-0 font-size-18">Create Proforma Invoice for Job Card: #{id}</h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><a href="javascript: void(0);">Garage</a></li>
                                <li className="breadcrumb-item active">Job Card</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <h4 className="card-title mb-3">Job Card Vehicle Details</h4>
                        <div className="col-12">
                            <div class="table-responsive">
                                <table class="table table-bordered border-secondary table-sm">
                                    <tbody>
                                        <tr>
                                            <th scope="row" style={{ backgroundColor: '#f2f2f2' }}>Works Done</th>
                                            <td>{jobcard ? jobcard.worksdone : ""}</td>
                                            <th scope="row" style={{ backgroundColor: '#f2f2f2' }}>Repaired By</th>
                                            <td>{jobcard ? jobcard.repairedby : ""}</td>
                                            <th scope="row" style={{ backgroundColor: '#f2f2f2' }}>Tested By</th>
                                            <td>{jobcard ? jobcard.testedby : ""}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ backgroundColor: '#f2f2f2' }}>Number Plate</th>
                                            <td>{jobcard.vehicle ? jobcard.vehicle.numberplate : ""}</td>
                                            <th scope="row" style={{ backgroundColor: '#f2f2f2' }}>Make</th>
                                            <td>{jobcard.vehicle ? jobcard.vehicle.makeId : ""}</td>
                                            <th scope="row" style={{ backgroundColor: '#f2f2f2' }}>Model</th>
                                            <td>{jobcard.vehicle ? jobcard.vehicle.modelId : ""}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ backgroundColor: '#f2f2f2' }}>Engine Number</th>
                                            <td>{jobcard.vehicle ? jobcard.vehicle.engineno : ""}</td>
                                            <th scope="row" style={{ backgroundColor: '#f2f2f2' }}>Engine Size</th>
                                            <td>{jobcard.vehicle ? jobcard.vehicle.enginesize : ""}</td>
                                            <th scope="row" style={{ backgroundColor: '#f2f2f2' }}>Chassis Number</th>
                                            <td>{jobcard.vehicle ? jobcard.vehicle.chassisno : ""}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ backgroundColor: '#f2f2f2' }}>YOM</th>
                                            <td>{jobcard.vehicle ? jobcard.vehicle.year : ""}</td>
                                            <th scope="row" style={{ backgroundColor: '#f2f2f2' }}>Mileage</th>
                                            <td>{jobcard.vehicle ? jobcard.vehicle.mileage : ""}</td>
                                            <th scope="row" style={{ backgroundColor: '#f2f2f2' }}>Transmission</th>
                                            <td>{jobcard.vehicle ? jobcard.vehicle.transmission : ""}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Add Costed Items Worked On <button className="btn btn-sm btn-soft-primary" onClick={addRow}>
                                <i className="mdi mdi-eye-outline"></i>Add Item
                            </button></h4>
                            <div className="col-lg-12">
                                <div className="table-responsive">
                                    <table className="table table-striped table-sm">
                                        <thead className="table-light">
                                            <tr>
                                                <th>Item / Particular</th>
                                                <th>Qty</th>
                                                <th>Rate</th>
                                                <th>Amount (UGX)</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rows.map((row) => (
                                                <tr key={row.id}>
                                                    <td>
                                                        <input type="text" className="form-control"
                                                            value={row.item}
                                                            onChange={(e) => handleInputChange(row.id, 'item', e.target.value)} />
                                                    </td>
                                                    <td>
                                                        <input type="number" className="form-control"
                                                            value={row.qty}
                                                            onChange={(e) => handleInputChange(row.id, 'qty', e.target.value)} />
                                                    </td>
                                                    <td>
                                                        <CurrencyFormat
                                                            value={row.rate}
                                                            thousandSeparator={true}
                                                            className="form-control"
                                                            onValueChange={(values) => handleInputChange(row.id, 'rate', values.floatValue)}
                                                        />
                                                    </td>
                                                    <td className="text-end">
                                                        <CurrencyFormat
                                                            value={row.amt}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            className="form-control"
                                                            readOnly
                                                            disabled
                                                        />
                                                    </td>
                                                    <td>
                                                        <ul className="list-unstyled hstack gap-1 mb-0">
                                                            <li onClick={() => deleteRow(row.id)} data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Delete" data-bs-original-title="Delete">
                                                                <a href="#jobDelete" className="btn btn-sm btn-soft-danger"><i className="mdi mdi-delete-outline"></i></a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan="3" className="text-end"><strong>Sub Total Amount</strong></td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={subTotal}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        className="form-control"
                                                        readOnly
                                                        disabled
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="3" className="border-0 text-end"><strong>Discount (%)</strong></td>
                                                <td className="text-end">
                                                    <input type="number" className="form-control" placeholder="" value={discount} onChange={(e) => setDiscount(parseFloat(e.target.value) || 0)} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="3" className="border-0 text-end"><strong>Net Total Amount</strong></td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={netTotal}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        className="form-control"
                                                        readOnly
                                                        disabled
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="3" className="border-0 text-end"><strong>VAT 18%</strong></td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={vat}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        className="form-control"
                                                        readOnly
                                                        disabled
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="3" className="text-end"><strong>Gross Total Amount</strong></td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={grossTotal}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        className="form-control"
                                                        readOnly
                                                        disabled
                                                    />
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <div className="float-end">
                                <button onClick={handleSubmit} className="btn btn-primary w-md waves-effect waves-light">
                                    {loading ? <FNSpinner /> : "Add Proforma Details"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default AddProforma;