import React, { Fragment, useState, useEffect, useRef } from "react";
import moment from 'moment';
import ReactToPrint from "react-to-print";
import CurrencyFormat from 'react-currency-format';
import API from "../../helpers/api";
import logo from './ssejp1.png'

const PrintProforma = ({ match }) => {

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [supply, setSupply] = useState({});

    const { id } = match.params;

    const componentRef = useRef();

    const loadSupply = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/supplies/${id}`);
            setSupply(res?.data.supply);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    const loadItems = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/supplies/items/${id}`);
            setItems(res?.data.items);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadItems();
    }, []);

    useEffect(() => {
        loadSupply();
    }, []);
    return (
        <Fragment>
             <div className="row d-flex justify-content-center mb-3">
                <div className="col-10">
                    <div class="d-print-none">
                        <div class="float-end">
                            <ReactToPrint
                                trigger={() => <button class="btn btn-warning w-md waves-effect waves-light">Print Proforma Invoice</button>}
                                content={() => componentRef.current}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row d-flex justify-content-center" ref={componentRef}>
                <div className="col-10">
                    <div className="card">
                        <div className="card-body">
                            <div style={{ width: '100%', height: '40vh', overflow: 'hidden' }}>
                                <img src={logo} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                            </div>

                            <section className='d-flex flex-row justify-content-between'>
                                <div>
                                    <h4> M/S : {supply.customer ? supply.customer.fullname : ''}</h4>
                                    <h5>  Address: {supply.customer ? supply.customer.address : ''}</h5>
                                </div>
                                <div>
                                    <h5> Proforma Date: {moment(supply.proformaDate).format('YYYY-MM-DD')}</h5>
                                    <h5>Proforma Invoice No: {supply.id}/2024</h5>
                                </div>
                            </section>
                            <section className="mt-4">
                                {/* <div class="py-2 mt-3">
                                    <h3 class="font-size-15 fw-bold">Job Card Items Worked On</h3>
                                </div> */}
                                <div class="table-responsive">
                                    <table class="table table-striped table-bordered border-secondary table-sm">
                                        <thead className="table-dark">
                                            <tr>
                                                <th width='600'>Description / Model</th>
                                                <th>Part No</th>
                                                <th>Serial No</th>
                                                <th>Qty</th>
                                                <th class="text-end">Unit Price</th>
                                                <th class="text-end">{`Amount  (${supply.currency})`}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items &&
                                                items.map((item) => (
                                                    <tr key={item.id}>
                                                        <td>{item.item}</td>
                                                        <td>{item.partno}</td>
                                                        <td>{item.serialno}</td>
                                                        <td>{item.qty}</td>
                                                        <td class="text-end">
                                                            <CurrencyFormat
                                                                value={item.unitprice}
                                                                displayType="text"
                                                                thousandSeparator
                                                            />
                                                        </td>

                                                        <td class="text-end">
                                                            <CurrencyFormat
                                                                value={item.amt}
                                                                displayType="text"
                                                                thousandSeparator
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            <tr>
                                                <td colspan="5" class="text-end"><strong>Sub Total Amount</strong></td>
                                                <td class="text-end">
                                                    <CurrencyFormat
                                                        value={supply.subTotal}
                                                        displayType="text"
                                                        thousandSeparator
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                {supply.hasdiscount ?
                                                    <>
                                                        <td colspan="5" class="border-0 text-end">
                                                            <strong>Discount</strong></td>
                                                        <td class="text-end">
                                                            <CurrencyFormat
                                                                value={supply.discount}
                                                                displayType="text"
                                                                thousandSeparator
                                                            />
                                                        </td>
                                                    </> : ''}
                                            </tr>
                                            <tr>
                                                {supply.hasdiscount ?
                                                    <>
                                                        <td colspan="5" class="border-0 text-end">
                                                            <strong>Net Total Amount</strong></td>
                                                        <td class="text-end">
                                                            <CurrencyFormat
                                                                value={supply.netTotal}
                                                                displayType="text"
                                                                thousandSeparator
                                                            />
                                                        </td>
                                                    </> : ''}

                                            </tr>
                                            <tr>
                                                {supply.hasvat ?
                                                    <>
                                                        <td colspan="5" class="border-0 text-end">
                                                            <strong>VAT</strong></td>
                                                        <td class="text-end">
                                                            <CurrencyFormat
                                                                value={supply.vat}
                                                                displayType="text"
                                                                thousandSeparator
                                                            />
                                                        </td>
                                                    </> : ''}
                                            </tr>
                                            <tr>
                                                <td colspan="5" class="text-end">
                                                    <strong>{`Gross Total Amount (${supply.currency})`}</strong></td>
                                                <td class="text-end"><h4 class="m-0">
                                                    <CurrencyFormat
                                                        value={supply.grossTotal}
                                                        displayType="text"
                                                        thousandSeparator
                                                    />
                                                </h4></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </section>
                            {/* <section>
                                <p>Notes to client</p>
                            </section> */}
                            <footer>
                                <ul className='list-unstyled'>
                                    <li>STANBIC BANK : 9030017839968 - GARDEN CITY.</li>
                                    <li>AC NAME: SSEJP ENGINEERING AND CONSULTS-SMC LTD</li>
                                    <li>  SWIFT: SBICUGKX</li>
                                </ul>
                            </footer>

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default PrintProforma