import React, { useState, useEffect, Fragment } from 'react';
import { toast } from "react-toastify";
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import API from "../../../helpers/api";
import FNSpinner from "../../../components/FNSpinner";

const EditJobCard = ({ match }) => {
    const [loading, setLoading] = useState(false);
    const [jobcard, setJobCard] = useState({});
    const [items, setItems] = useState([]);
    const [vehicleId, setVehicleId] = useState("");
    const [customerId, setCustomerId] = useState("");
    const [vehicles, setVehicles] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [rows, setRows] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);

    const history = useHistory();
    const { id } = match.params;

    const loadJobCard = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/jobcard/${id}`);
            console.log("Job Cards ===>", res);
            setJobCard(res?.data.job);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    const loadItems = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/jobcard/items/${id}`);
            setItems(res?.data.items);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadJobCard();
        loadItems();
    }, []);

    // useEffect(() => {
    //     loadVehicle();
    //     loadCustomer();
    // }, []);

    useEffect(() => {
        if (rows.length === 0) {
            setRows([createNewRow()]);
        }
    }, []);

    useEffect(() => {
        const total = rows.reduce((total, row) => total + parseNumber(row.amt), 0);
        setTotalAmount(total);
    }, [rows]);

    const handleChangeVehicle = (selectedOption) => {
        setVehicleId(selectedOption.value);
    };

    const handleChangeCustomer = (selectedOption) => {
        setCustomerId(selectedOption.value);
    };

    const createNewRow = () => ({
        id: Date.now(),
        item: '',
        qty: '',
        rate: '',
        amt: '',
    });

    const addRow = () => {
        setRows([...rows, createNewRow()]);
    };

    const formatNumber = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const parseNumber = (str) => {
        return parseFloat(str.replace(/,/g, '')) || 0;
    };

    const calculateAmount = (qty, rate) => {
        const quantity = parseNumber(qty);
        const rateValue = parseNumber(rate);
        return formatNumber((quantity * rateValue));
    };

    const handleItemChange = (id, field, value) => {
        setItems((prevItems) =>
            prevItems.map((item) =>
                item.id === id ? { ...item, [field]: value } : item
            )
        );
    };

    const handleInputChange = (id, field, value) => {
        if (field === 'qty' || field === 'rate') {
            value = value.replace(/[^0-9]/g, '');
        }
        setRows((prevRows) =>
            prevRows.map((row) =>
                row.id === id
                    ? {
                        ...row,
                        [field]: field === 'qty' || field === 'rate' ? formatNumber(value) : value,
                        amt: field === 'qty' || field === 'rate' ? calculateAmount(field === 'qty' ? value : row.qty, field === 'rate' ? value : row.rate) : row.amt
                    }
                    : row
            )
        );
    };

    const deleteRow = (id) => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const requestData = {
            customerId, vehicleId, totalAmount,
            rows: rows.map(row => ({
                item: row.item,
                qty: row.qty,
                rate: row.rate,
                amt: row.amt,
            }))
        };

        try {
            const response = await API.post("/jobcard", requestData);
            setLoading(false);
            history.push('/jobcard/view');
            toast.success(`Job Card Has Been Added Successfully`);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
            toast.error("Error while Adding Job Card");
        }
    };

    return (
        <Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="d-sm-flex align-items-center justify-content-between mb-3">
                        <h4 className="mb-sm-0 font-size-18">Update Job Card: #{jobcard.id}</h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><a href="javascript: void(0);">Garage</a></li>
                                <li className="breadcrumb-item active">Job Card</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-4">
                    <div className="mb-3">
                        <label htmlFor="kycselectcity-input" className="form-label">Select Customer</label>
                        <Select
                            defaultValue={jobcard.customerId}
                            onChange={handleChangeCustomer}
                            options={customers.map(customer => ({ value: customer.id, label: `${customer.firstname} ${customer.lastname}` }))}
                            placeholder="Select Customer Details"
                        />
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="mb-3">
                        <label htmlFor="kycselectcity-input" className="form-label">Select Vehicle Number Plate</label>
                        <Select
                            defaultValue={jobcard.vehicleId}
                            onChange={handleChangeVehicle}
                            options={vehicles.map(vehicle => ({ value: vehicle.id, label: vehicle.numberplate }))}
                            placeholder="Select Vehicle Number Plate"
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Job Card Description Items Worked On</h4>
                            <div className="col-lg-12">
                                <div className="table-responsive">
                                    <table className="table table-striped table-sm">
                                        <thead className="table-light">
                                            <tr>
                                                <th>Item / Particular</th>
                                                <th>Qty</th>
                                                <th>Rate</th>
                                                <th>Amount (UGX)</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items.map((item) => (
                                                <tr key={item.id}>
                                                    <td>
                                                        <input type="text" className="form-control"
                                                            value={item.item}
                                                            onChange={(e) => handleItemChange(item.id, 'item', e.target.value)} />
                                                    </td>
                                                    <td>
                                                        <input type="text" className="form-control"
                                                            value={item.qty}
                                                            onChange={(e) => handleItemChange(item.id, 'qty', e.target.value)} />
                                                    </td>
                                                    <td>
                                                        <input type="text" className="form-control"
                                                            value={item.rate}
                                                            onChange={(e) => handleItemChange(item.id, 'rate', e.target.value)} />
                                                    </td>
                                                    <td>
                                                        <input type="text" className="form-control"
                                                            value={item.amt}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </td>
                                                    <td>
                                                        <ul className="list-unstyled hstack gap-1 mb-0">
                                                            <li onClick={addRow} data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Add" data-bs-original-title="Add">
                                                                <a href="#add" className="btn btn-sm btn-soft-primary"><i className="mdi mdi-eye-outline"></i></a>
                                                            </li>
                                                            <li onClick={() => deleteRow(item.id)} data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Delete" data-bs-original-title="Delete">
                                                                <a href="#jobDelete" className="btn btn-sm btn-soft-danger"><i className="mdi mdi-delete-outline"></i></a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan="3" className="text-end"><strong>Total Amount (UGX):</strong></td>
                                                <td colSpan="4">{formatNumber(totalAmount)}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <div className="float-end">
                                <button onClick={handleSubmit} className="btn btn-primary w-md waves-effect waves-light">Create Job Card</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default EditJobCard;
