/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'

const GarageBar = () => {
    return (
        <ul class="navbar-nav">
            <li class="nav-item dropdown">
                <Link class="nav-link dropdown-toggle arrow-none" to="/fleet/dashboard" role="button">
                    <i class="bx bx-home-circle me-2"></i><span key="t-dashboards">Dashboard</span>
                </Link>
            </li>
            <li class="nav-item dropdown">
                <Link class="nav-link dropdown-toggle arrow-none" to="/customers" id="topnav-components" role="button"
                >
                    <i class="bx bx-customize me-2"></i><span key="t-components">Customers</span>
                </Link>
            </li>
            <li class="nav-item dropdown">
                <Link class="nav-link dropdown-toggle arrow-none" to="/supplies" id="topnav-components" role="button"
                >
                    <i class="bx bx-customize me-2"></i><span key="t-components">Supplies</span>
                </Link>
            </li>
            <li class="nav-item dropdown">
                <Link class="nav-link dropdown-toggle arrow-none" to="/vehicles" id="topnav-components" role="button"
                >
                    <i class="bx bx-layout me-2"></i><span key="t-components">Vehicles</span>
                </Link>
            </li>
            <li class="nav-item dropdown">
                <Link class="nav-link dropdown-toggle arrow-none" to="/jobcard/create" role="button">
                    <i class="bx bx-tone me-2"></i><span key="t-dashboards">Create Job Card</span>
                </Link>
            </li>
            <li class="nav-item dropdown">
                <Link class="nav-link dropdown-toggle arrow-none" to="/jobcard/view" id="topnav-components" role="button"
                >
                    <i class="bx bx-collection me-2"></i><span key="t-components">View Job Cards</span>
                </Link>
            </li>
            <li class="nav-item dropdown">
                <Link class="nav-link dropdown-toggle arrow-none" to="/proforma" id="topnav-components" role="button"
                >
                    <i class="bx bx-layout me-2"></i><span key="t-components">Print Proforma / Invoice</span>
                </Link>
            </li>
            {/* <li class="nav-item dropdown">
                <Link class="nav-link dropdown-toggle arrow-none" to="/fleet/maintenance" id="topnav-components" role="button"
                >
                    <i class="bx bx-file me-2"></i><span key="t-components">View Invoices</span>
                </Link>
            </li> */}
            {/* <li class="nav-item dropdown">
                <Link class="nav-link dropdown-toggle arrow-none" to="/spareparts" id="topnav-components" role="button"
                >
                    <i class="bx bx-customize me-2"></i><span key="t-components">SpareParts</span>
                </Link>
            </li> */}
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle arrow-none" href="#" id="topnav-layout" role="button"
                >
                    <i class="bx bx-layout me-2"></i><span key="t-layouts">Master Settings</span> <div class="arrow-down"></div>
                </a>
                <div class="dropdown-menu" aria-labelledby="topnav-layout">
                    <div class="dropdown">
                        <a class="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-layout-verti"
                            role="button">
                            <span key="t-vertical">Vehicle Settings</span> <div class="arrow-down"></div>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="topnav-layout-verti">
                            <Link to="/vehicles/make" class="dropdown-item" key="t-compact-sidebar">Vehicle Make</Link>
                            <Link to="/vehicles/model" class="dropdown-item" key="t-light-sidebar">Vehicle Model</Link>
                        </div>
                    </div>
                    <div class="dropdown">
                        <a class="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-layout-hori"
                            role="button">
                            <span key="t-horizontal">Garage Management</span> <div class="arrow-down"></div>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="topnav-layout-verti">
                            <Link to="/spareparts/category" class="dropdown-item" key="t-light-sidebar">Spare Part Category</Link>
                            {/* <Link to="/fleet/masters/garages" class="dropdown-item" key="t-light-sidebar">Garages</Link> */}
                        </div>
                    </div>
                    <div class="dropdown">
                        <a class="dropdown-item dropdown-toggle arrow-none" href="#" id="topnav-layout-hori"
                            role="button">
                            <span key="t-horizontal">Masters Management</span> <div class="arrow-down"></div>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="topnav-layout-verti">
                            <Link to="/fleet/masters/drivers" class="dropdown-item" key="t-light-sidebar">Drivers</Link>
                            {/* <Link to="/fleet/masters/departments" class="dropdown-item" key="t-compact-sidebar">Departments</Link> */}
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    )
}

export default GarageBar