/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useState, useEffect, useRef } from "react";
import CurrencyFormat from 'react-currency-format';
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";
import Select from 'react-select';
import API from "../../../helpers/api";
import FNModal from '../../../components/FNModal'
import AddDiscount from "./AddDiscount";
import FNSpinner from '../../../components/FNSpinner'

const JobCardDetail = ({ match }) => {

    const [jobcard, setJobCard] = useState({});
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const { id } = match.params;
    const componentRef = useRef();

    const loadJobCard = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/jobcard/${id}`);
            console.log("Job Cards ===>", res)
            setJobCard(res?.data.job);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    const loadItems = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/jobcard/items/${id}`);
            setItems(res?.data.items);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadJobCard();
        loadItems();
    }, []);

    return (
        <>
            <FNModal
                showModal={showModal}
                handleClose={handleClose}
                lg=""
                title="Generate Proforma"
            >
                <AddDiscount close={handleClose} totalAmt={jobcard.totalAmount} id={id} />
            </FNModal>
            <div class="row">
                <div class="col-12">
                    <div class="float-end">
                        <ReactToPrint
                            trigger={() => <button class="btn btn-success w-md waves-effect waves-light"><i class="fa fa-print"></i>Print Job Card</button>}
                            content={() => componentRef.current}
                        />
                        <button onClick={handleShow} class="btn btn-secondary w-md waves-effect waves-light">Generate Proforma</button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12 p-5" ref={componentRef}>
                    <div class="card">
                        <div class="card-body">
                            <div class="invoice-title">
                                <h4 class="float-end font-size-16">Number Plate # <span class="badge bg-warning"></span>{jobcard.vehicle ? jobcard.vehicle.numberplate : ''}</h4>
                                <div class="auth-logo mb-4">
                                    <h4>Job Card Detailed Report</h4>
                                </div>
                            </div>
                            <hr />
                            <div class="row">
                                <div class="col-4">
                                    <address>
                                        {/* <h5>Customer Details:</h5> */}
                                        <strong>Customer Name:</strong> {jobcard.customer ? `${jobcard.customer.firstname} ${jobcard.customer.lastname}` : ''}<br />
                                        Address: {jobcard.customer ? jobcard.customer.address : ''}<br />
                                        Phone Number: {jobcard.customer ? jobcard.customer.phoneno : ''}<br />
                                        Email: {jobcard.customer ? jobcard.customer.email : ''}<br />
                                    </address>
                                </div>
                                <div class="col-4">
                                    <address>
                                        <strong>Category</strong><br />
                                        Model: {jobcard.brand}<br />
                                        Type: {jobcard.model}<br />
                                        ChassisNo: {jobcard.vehicle ? jobcard.vehicle.chassisno : ''}<br />
                                    </address>
                                </div>
                                <div class="col-4 text-sm-end">
                                    <address class="mt-2 mt-sm-0">
                                        <strong>Engine Size:</strong>{jobcard.vehicle ? jobcard.vehicle.enginesize : ''}<br />
                                        Transmission: {jobcard.vehicle ? jobcard.vehicle.transmission : ''}<br />
                                        Mileage: {jobcard.vehicle ? jobcard.vehicle.mileage : ''}<br />
                                        Fuel Type: {jobcard.vehicle ? jobcard.vehicle.fuel : ''}<br />
                                    </address>
                                </div>
                            </div>
                            <div class="py-2 mt-3">
                                <h3 class="font-size-15 fw-bold">Job Card Description Items Worked On</h3>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-striped table-bordered border-secondary table-sm">
                                    <thead className="table-dark">
                                        <tr>
                                            <th style={{ width: '70px' }}>Item/Particulars</th>
                                            <th style={{ width: '70px' }}>Qty</th>
                                            <th style={{ width: '70px' }} class="text-end">Rate</th>
                                            <th style={{ width: '70px' }} class="text-end">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody table-bordered border-secondary>
                                        {items &&
                                            items.map((item) => (
                                                <tr key={item.id}>
                                                    <td>{item.item}</td>
                                                    <td>{item.qty}</td>
                                                    <td class="text-end">
                                                        <CurrencyFormat
                                                            value={item.rate}
                                                            displayType="text"
                                                            thousandSeparator
                                                        /></td>
                                                    <td class="text-end">
                                                        <CurrencyFormat
                                                            value={item.amt}
                                                            displayType="text"
                                                            thousandSeparator
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        <tr>
                                            <td colspan="3" class="text-end"><strong>Total Amount</strong></td>
                                            <td class="text-end">
                                                <CurrencyFormat
                                                    value={jobcard.totalAmount}
                                                    displayType="text"
                                                    thousandSeparator
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default JobCardDetail