import React, { Fragment } from 'react'
import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Layout from './components/Layout';
import Login from './pages/Auth/Login';
import VehicleModel from './pages/Fleet/Settings/Model'
import VehicleMake from './pages/Fleet/Settings/Make'
import SpareParts from './pages/Fleet/SpareParts';
import Drivers from './pages/Fleet/Settings/Drivers';
import Garages from './pages/Fleet/Settings/Garages';
import SpareCategory from './pages/Fleet/Settings/SpareCategory';
import Departments from './pages/Fleet/Settings/Departments';
import FleetDashboard from './pages/Fleet/Dashboard';
import ServiceHistory from './pages/Fleet/Reports/ServiceHistory';
import ViewJobCards from './pages/Fleet/JobCards'
import CreateJob from './pages/Fleet/JobCards/CreateJob';
import EditJobCard from './pages/Fleet/JobCards/EditJobCard';
import JobCardDetail from './pages/Fleet/JobCards/JobCardDetail';
import AddProforma from './pages/Fleet/proforma/AddProforma';
import PrintProforma from './pages/Fleet/proforma/PrintProforma';
import PrintInvoice from './pages/Fleet/Invoice/PrintInvoice';
import Proforma from './pages/Fleet/proforma';
import Customers from './pages/Fleet/Customers';
import Vehicles from './pages/Fleet/Vehicles';
import Supplies from './pages/Supplies';
import AddSupply from './pages/Supplies/AddSupply';
import EditSupply from './pages/Supplies/EditSupply';
import SupplyInvoice from './pages/Supplies/PrintInvoice';
import SupplyProforma from './pages/Supplies/PrintProforma';

const App = () => {
  return (
    <Fragment>
      <ToastContainer />
      <Switch>
        <Route exact path="/" component={Login} />
        <Layout>
          <Route exact path="/vehicles/model" component={VehicleModel} />
          <Route exact path="/vehicles/make" component={VehicleMake} />
          <Route exact path="/fleet/spareparts" component={SpareParts} />
          <Route exact path="/fleet/masters/drivers" component={Drivers} />
          <Route exact path="/fleet/masters/garages" component={Garages} />
          <Route exact path="/spareparts/category" component={SpareCategory} />
          <Route exact path="/fleet/masters/departments" component={Departments} />
          <Route exact path="/fleet/dashboard" component={FleetDashboard} />
          <Route exact path="/fleet/reports/servicehistory" component={ServiceHistory} />
          <Route exact path="/invoice/print/:id" component={PrintInvoice} />
          <Route exact path="/proforma" component={Proforma} />
          <Route exact path="/proforma/create/:id" component={AddProforma} />
          <Route exact path="/proforma/view/:id" component={PrintProforma} />
          <Route exact path="/jobcard/print/:id" component={JobCardDetail} />
          <Route exact path="/jobcard/view" component={ViewJobCards} />
          <Route exact path="/jobcard/create" component={CreateJob} />
          <Route exact path="/jobcard/update/:id" component={EditJobCard} />
          <Route exact path="/Customers" component={Customers} />
          <Route exact path="/vehicles" component={Vehicles} />
          <Route exact path="/supplies" component={Supplies} />
          <Route exact path="/supplies/create" component={AddSupply} />
          <Route exact path="/supplies/edit/:id" component={EditSupply} />
          <Route exact path="/supplies/proforma/:id" component={SupplyProforma} />
          <Route exact path="/supplies/invoice/:id" component={SupplyInvoice} />
        </Layout>
      </Switch>
    </Fragment>
  )
}

export default App